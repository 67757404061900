import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

import Utils from "../../Utils/commonUtil";
import Constants from "../../Constants/Constants";
import API from "../../Services/Api";

import Background1 from "../Assets/loginImg1.png";
import Background2 from "../Assets/loginImg2.png";

const resetPasswordAPI = new API({ url: Constants.CONFIG.BASE_URL });
resetPasswordAPI.createEntity({
  name: Constants.ENTITIES.AUTH,
  uri: "auth/resetPassword",
});
class ConfirmPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      token: "",
      email: "",
      id: "",
      password: "",
      verifyPassword: "",
      isPasswordValid: true,
      isVerifyPasswordValid: true,
      errorMessageforverifyPassword: "",
      errorMessageforpassword: "",
      errorMessage: "",
      isBtnDisabled: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      userId: this.props.location.state.userId,
      token: this.props.location.state.token,
      email: this.props.location.state.email,
      id: this.props.location.state.id,
    });
  };

  handlePassword = (e) => {
    this.setState({
      isPasswordValid: true,
      errorMessageforpassword: "",
      password: Utils.leftTrim(e.target.value),
    });
  };
  handleVerifyPassword = (e) => {
    this.setState({
      isVerifyPasswordValid: true,
      errorMessageforverifyPassword: "",
      verifyPassword: Utils.leftTrim(e.target.value),
    });
  };
  networkCallForResetPassword = () => {
    this.setState({ isBtnDisabled: true });
    let body = { id: this.state.id.toString() };
    body.password = this.state.password.toString();
    body.userId = this.state.userId.toString();
    body.email = this.state.email.toString();

    resetPasswordAPI.endpoints.auth
      .create(body, {
        config: {
          headers: {
            Authorization: "bearer " + this.state.token.toString(),
          },
        },
      })
      .then((data) => {
        if (data.data.status === "success") {
          this.setState({ redirectToLogin: true, isBtnDisabled: false });
        } else {
          this.setState({ isBtnDisabled: false });
        }
      });
  };
  resetPassword = (e) => {
    e.preventDefault();
    let networkCall = true;
    if (this.state.password === "") {
      this.setState({
        isPasswordValid: false,
        errorMessageforpassword: "Enter password",
      });
      networkCall = false;
    }
    if (this.state.verifyPassword === "") {
      this.setState({
        isVerifyPasswordValid: false,
        errorMessageforverifyPassword: "Enter password",
      });
      networkCall = false;
    }
    if (this.state.password !== this.state.verifyPassword) {
      this.setState({
        isPasswordValid: false,
        isVerifyPasswordValid: false,
        errorMessage: "Password does not match.",
      });
      networkCall = false;
    }
    if (networkCall) {
      this.networkCallForResetPassword();
    }
  };
  redirectToLogin = () => {
    this.setState({ redirectToLogin: true });
  };
  render() {
    if (this.state.redirectToLogin) {
      return <Redirect push to="/"></Redirect>;
    }
    return (
      <div
        className="bgLight"
        style={{
          backgroundImage: `url(${Background1}),url(${Background2})`,
        }}
      >
        <div className="app flex-row align-items-center">
          <Container>
            <Col md="12" className="text-center">
              <img
                src="../../../../selectivaorgfinallogo.png"
                style={{ maxWidth: "200px", margin: "40px 0" }}
              ></img>
            </Col>
            <Row className="justify-content-center">
              <Col md="5">
                <CardGroup>
                  <Card className="p-4 cardShadow">
                    <CardBody>
                      <Form onSubmit={this.resetPassword}>
                        <h3 className="mb-3">Reset Password</h3>
                        {this.state.errorMessage !== "" ? (
                          <blockquote
                            style={{
                              margin: "24px 0",
                              padding: "4px 7px",
                              borderLeft: "5px solid rgb(0, 0, 0, 0.5)",
                              backgroundColor: "rgba(255, 100, 100, 0.2)",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0",
                                fontSize: "14px",
                              }}
                            >
                              <i class="icon-info icons d-inline-block align-middle mr-3 text-danger font-xl"></i>
                              {this.state.errorMessage}
                            </p>
                          </blockquote>
                        ) : (
                          ""
                        )}
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Enter new password"
                            autoComplete="new-password"
                            onChange={this.handlePassword}
                            value={this.state.password}
                            className={
                              !this.state.isPasswordValid ? "is-invalid" : ""
                            }
                          />
                          <div
                            className="invalid-feedback d-block"
                            style={{ fontSize: "14px", marginLeft: "38px" }}
                          >
                            {this.state.errorMessageforpassword}
                          </div>
                        </InputGroup>

                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Confirm  password"
                            autoComplete="confirm-password"
                            onChange={this.handleVerifyPassword}
                            value={this.state.verifyPassword}
                            className={
                              !this.state.isVerifyPasswordValid
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div
                            className="invalid-feedback d-block"
                            style={{ fontSize: "14px", marginLeft: "38px" }}
                          >
                            {this.state.errorMessageforverifyPassword}
                          </div>
                        </InputGroup>

                        <Row>
                          <Col xs="6">
                            <Button
                              outline
                              size="sm"
                              color="primary"
                              onClick={this.redirectToLogin}
                              disabled={this.state.isBtnDisabled ? true : false}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs="6" className="text-right">
                            <Button
                              inline
                              color="primary"
                              size="sm"
                              className="font-weight-medium"
                              disabled={this.state.isBtnDisabled ? true : false}
                            >
                              {this.state.isBtnDisabled ? (
                                <span
                                  style={{ marginRight: "5px" }}
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                              Reset Password
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default ConfirmPassword;
