import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Constants from "../../Constants/Constants";
import API from "../../Services/Api";
import Context from "../../Context/Context";

const importContactAPI = new API({ url: Constants.CONFIG.BASE_URL });
importContactAPI.createEntity({
  name: Constants.ENTITIES.EMAIL,
  uri: "email/importContacts",
});

class ContactAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listId: "",
    };
  }

  componentDidMount = () => {
    if (this.context.idListId && this.context.provider) {
      let params = {
        idListId: this.context.idListId,
        provider: this.context.provider,
      };
      this.contactOAuthCall(params).then((data) => this.contactOAuthData(data));
    } else {
      let urlString = window.location.href;
      let url = new URL(urlString);
      if (url.searchParams.get("state")) {
        let state = url.searchParams.get("state").split(",");
        let idListId = state[0];
        let provider = state[1];
        let code = url.searchParams.get("code");
        if (idListId !== "" && code !== "" && provider !== "") {
          let params = {
            idListId: idListId,
            provider: provider,
            authCode: code.toString(),
          };
          this.contactOAuthCall(params)
            .then((data) => {
              this.contactOAuthData(data);
            })
            .catch((e) => this.closeWindow());
        } else {
          this.context.updateContext([
            { key: "toastStatus", value: true },
            { key: "toastType", value: Constants.TOAST_STATUS.ERROR },
            { key: "contactData", value: {} },
          ]);
          this.closeWindow();
          this.setState({ redirectToList: true });
        }
      } else {
        this.setState({ redirectToList: true });
      }
    }
  };

  closeWindow = () => {
    window.close();
  };

  contactOAuthCall = (params) => {
    return importContactAPI.endpoints.email.getAll({
      config: {
        headers: {
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        params: params,
      },
    });
  };

  contactOAuthData = (data) => {
    let networkCallData = data.data.data;
    if (data.data.status === "success") {
      if ("isContact" in networkCallData) {
        if (!networkCallData.isContact) {
          this.openAuthWindow(
            networkCallData.authCodeUrl,
            this.context.provider
          );
          this.context.updateContext([{ key: "ORGID", value: "" }]);
        } else {
          let contacts = {};
          let isContact = networkCallData.isContact;
          let _data = networkCallData.contacts;
          contacts.blockedEmailContacts = _data.blockedEmailContacts.length;
          contacts.duplicateEmailContacts = _data.duplicateEmailContacts.length;
          contacts.invalidDomainEmails = _data.invalidDomainEmails.length;
          contacts.invalidEmailContacts = _data.invalidEmailContacts.length;
          contacts.isZeroBounce = _data.isZeroBounce;
          contacts.suppressedEmailContacts =
            _data.suppressedEmailContacts.length;
          contacts.toBeInsert = _data.toBeInsert.length;
          localStorage.setItem("IsContact", isContact);
          localStorage.setItem("AuthDataCount", JSON.stringify(contacts));
          this.closeWindow();
          this.setState({ redirectToList: true });
        }
      } else if (networkCallData && Object.keys(networkCallData).length !== 0) {
        this.context.updateContext([
          { key: "toastStatus", value: true },
          {
            key: "toastType",
            value: Constants.TOAST_STATUS.ADDED,
          },
          { key: "contactData", value: networkCallData },
        ]);
        this.closeWindow();
        this.setState({ redirectToList: true });
      }
    } else if (data.data.status === "fail") {
      if (data.data.message) {
        if (data.data.message.successFullySavedContacts) {
          if (data.data.message.successFullySavedContacts.length !== 0) {
            this.context.updateContext([
              { key: "toastStatus", value: true },
              { key: "toastType", value: Constants.TOAST_STATUS.CREATE },
              { key: "contactData", value: data.data.message },
            ]);
            this.closeWindow();
            this.setState({ redirectToList: true });
          } else {
            this.closeWindow();
            this.setState({ redirectToList: true });
          }
        } else if (data.data.message.statusText === "Bad Request") {
          localStorage.setItem("ToastStatus", "error");
          this.context.updateContext([
            { key: "toastStatus", value: true },
            { key: "toastType", value: Constants.TOAST_STATUS.ERROR },
            { key: "contactData", value: {} },
          ]);
          this.closeWindow();
          this.setState({ redirectToList: true });
        } else {
          localStorage.setItem("ToastStatus", "error");
          this.context.updateContext([
            { key: "toastStatus", value: true },
            { key: "toastType", value: Constants.TOAST_STATUS.ERROR },
            { key: "contactData", value: {} },
          ]);
          this.closeWindow();
          this.setState({ redirectToList: true });
        }
      } else {
        localStorage.setItem("ToastStatus", "error");
        this.context.updateContext([
          { key: "toastStatus", value: true },
          { key: "toastType", value: Constants.TOAST_STATUS.ERROR },
          { key: "contactData", value: {} },
        ]);
        this.closeWindow();
        this.setState({ redirectToList: true });
      }
    }
  };

  componentWillUpdate = () => {
    this.closeWindow();
  };
  openAuthWindow = (authUrl, name) => {
    let windowObjectReference = null;
    let previousUrl = null;
    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
       or if such pointer exists but the window was closed */
      windowObjectReference = window.open(
        authUrl,
        name,
        Constants.CONTACT_AUTH_WINDOW_FEATURES
      );
    } else if (previousUrl !== authUrl) {
      windowObjectReference = window.open(
        authUrl,
        name,
        Constants.CONTACT_AUTH_WINDOW_FEATURES
      );
      windowObjectReference.focus();
    } else {
      windowObjectReference.focus();
    }
    var popupTick = setInterval(() => {
      if (windowObjectReference.closed) {
        clearInterval(popupTick);
        if (localStorage.getItem("IsContact") !== "true") {
          localStorage.setItem("ToastStatus", "error");
          this.setState({ redirectToList: true });
        } else {
          this.setState({ redirectToList: true });
        }
      }
    }, 500);
    previousUrl = authUrl;
  };

  checkPopupBlocker = (poppedWindow) => {
    var result = false;

    try {
      if (typeof poppedWindow == "undefined") {
        // Safari with popup blocker... leaves the popup window handle undefined
        result = true;
      } else if (poppedWindow && poppedWindow.closed) {
        // This happens if the user opens and closes the client window...
        // Confusing because the handle is still available, but it's in a "closed" state.
        // We're not saying that the window is not being blocked, we're just saying
        // that the window has been closed before the test could be run.
        result = false;
      } else if (poppedWindow && poppedWindow.test) {
        // This is the actual test. The client window should be fine.
        result = false;
      } else {
        // Else we'll assume the window is not OK
        result = true;
      }
    } catch (err) {}

    return result;
  };

  render() {
    if (this.state.redirectToList) {
      return (
        <Redirect
          push
          to={{
            pathname:
              "/" +
              this.context.selectedContext.replace(/\s+/g, "-") +
              "/contact/list/" +
              this.context.idListId,
          }}
        ></Redirect>
      );
    }
    return <div>Please wait</div>;
  }
}
ContactAuth.contextType = Context;
export default ContactAuth;
