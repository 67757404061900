import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

import MyContext from "../../Context/Context";
import API from "../../Services/Api";
import Constants from "../../Constants/Constants";
import Utils from "../../Utils/commonUtil";
import Background1 from "../Assets/loginImg1.png";
import Background2 from "../Assets/loginImg2.png";

const verifyEmailAPI = new API({ url: Constants.CONFIG.BASE_URL });
verifyEmailAPI.createEntity({
  name: Constants.ENTITIES.AUTH,
  uri: "auth/forgotPassword",
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirctToLogin: false,
      email: "",
      isEmailValid: true,
      errorMessage: "",
      _redirectToOtp: false,
      isSubmitDisabled: false,
    };
  }

  redirctToLogin = () => {
    this.setState({ redirctToLogin: true });
  };

  handleEmail = (e) => {
    this.setState({
      isEmailValid: true,
      email: Utils.leftTrim(e.target.value),
    });
  };
  networkCallForVarifyEmail = (body) => {
    this.setState({ isSubmitDisabled: true });
    verifyEmailAPI.endpoints.auth
      .create(body, {
        config: {},
      })
      .then((data) => {
        let networkCallData = data.data.data;
        if (data.data.status === "success") {
          this.setState({
            data: {
              userId: networkCallData.userId,
              token: networkCallData.token,
              id: networkCallData.id,
            },
            _redirectToOtp: true,
            isSubmitDisabled: false,
          });
        } else {
          this.setState({
            isEmailValid: false,
            errorMessage: data.data.message,
            isSubmitDisabled: false,
          });
        }
      });
  };
  verifyEmail = (e) => {
    e.preventDefault();
    let emailRegex =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let body = { email: this.state.email };
    if (this.state.email === "" || !emailRegex.test(this.state.email)) {
      this.setState({
        isEmailValid: false,
        errorMessage: "Provide a valid email",
      });
    } else {
      this.networkCallForVarifyEmail(body);
    }
  };
  render() {
    if (this.state.redirctToLogin) {
      return <Redirect push to="/"></Redirect>;
    }
    if (this.state._redirectToOtp) {
      return (
        <Redirect
          push
          to={{
            pathname: "/verifyotp",
            state: {
              userId: this.state.data.userId,
              email: this.state.email,
              token: this.state.data.token,
              id: this.state.data.id,
            },
          }}
        ></Redirect>
      );
    }

    return (
      <div
        className="bgLight"
        style={{
          backgroundImage: `url(${Background1}),url(${Background2})`,
        }}
      >
        <div className="app flex-row align-items-center">
          <Container>
            <Col md="12" className="text-center">
              <img
                src="../../../../selectivaorgfinallogo.png"
                style={{ maxWidth: "200px", margin: "40px 0" }}
              ></img>
            </Col>
            <Row className="justify-content-center">
              <Col md="5">
                <CardGroup>
                  <Card className="p-4 cardShadow">
                    <CardBody>
                      <Form onSubmit={this.verifyEmail}>
                        <h3 className="mb-3">Forgot your password ?</h3>

                        <InputGroup className="mb-1">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-envelope-o"></i>
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            type="text"
                            placeholder="Email Address"
                            autoComplete="email"
                            onChange={this.handleEmail}
                            className={
                              !this.state.isEmailValid ? "is-invalid" : ""
                            }
                            value={this.state.email}
                          />
                        </InputGroup>
                        {!this.state.isEmailValid ? (
                          <p
                            className="invalid-feedback d-block"
                            style={{ fontSize: "14px", marginLeft: "38px" }}
                          >
                            {this.state.errorMessage}
                          </p>
                        ) : (
                          ""
                        )}

                        <Row className="mt-4">
                          <Col xs="6">
                            <Button
                              outline
                              size="sm"
                              color="primary"
                              onClick={this.redirctToLogin}
                              disabled={
                                this.state.isSubmitDisabled ? true : false
                              }
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs="6" className="text-right">
                            <Button
                              inline
                              color="primary"
                              size="sm"
                              className="font-weight-medium"
                              disabled={
                                this.state.isSubmitDisabled ? true : false
                              }
                            >
                              {this.state.isSubmitDisabled ? (
                                <span
                                  style={{ marginRight: "5px" }}
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                              Next
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
ForgotPassword.contextType = MyContext;
export default ForgotPassword;
