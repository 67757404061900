import React, { Suspense } from "react";
import "./Homepage.css";
import Header from "../Header";
import { Container, Button, Label, FormGroup, Input } from "reactstrap";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
import MyContext from "../../Context/Context";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TableSkeleton from "../TableSkeleton";
import Constants from "../../Constants/Constants";
import API from "../../Services/Api";
import NotFound from "../NotFound";
import { controllers } from "chart.js";
import Chip from "@material-ui/core/Chip";
const ClubAPI = new API({ url: Constants.CONFIG.BASE_URL });
ClubAPI.createEntity({ name: Constants.ENTITIES.ORGANIZATION_CLUB });
const StateAPI = new API({ url: Constants.CONFIG.BASE_URL });
StateAPI.createEntity({
  name: Constants.ENTITIES.ORGANIZATION,
  uri: "organizations/usdIdsByState",
});
StateAPI.createEntity({
  name: Constants.ENTITIES.USD,
  uri: "organizations/orgIdsByUsdIds",
});

const ORG_API = new API({ url: Constants.CONFIG.BASE_URL });
ORG_API.createEntity({
  name: Constants.ENTITIES.ORGANIZATION,
  uri: "organizations",
});

const USD_API = new API({ url: Constants.CONFIG.BASE_URL });
USD_API.createEntity({
  name: Constants.ENTITIES.USD,
  uri: "usd/list",
});

/**
 * This Component is the parent component of the application where all the other component mounts according to the roues and conditions
 * Routes and tabs of for the particular context are loaded into the DOM for here
 */
let _dropDownFilterForContext = {};
class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrganizationValue: { id: 0, title: "All" },
      selectedValueForFilter: { title: Constants._DEFAULT_STRING },
      dropdownPermission: { state: true, usd: false, club: false, org: false },
      usdOptions: [],
      clubOptions: [],
      organizationOptions: [],
      selectedState: { id: "", title: "All" },
      selectedUSD: { id: "", title: "All" },
      selectedOrganization: { id: "", title: "All" },
      selectedClub: { id: "", title: "All" },
      // oldTimeValue: new Date().getTime(),
      reset: false,
    };
  }
  /**
   * This function will be called just after the component is mounted
   * It stores the selected value for filter and selected value of context into state variable
   * @param{null}
   * @returns{null}
   */

  componentDidMount = () => {
    this.context.updateContext([
      { key: "selectedOrganization", value: "" },
      { key: "selectedClub", value: "" },
      { key: "selectedUSD", value: "" },
    ]);
    _dropDownFilterForContext = {};
    let statesArray = [];
    let OrgList = [];
    try {
      this.context.states.map((_data, index) => {
        if (_data.name != null) {
          statesArray.push({ id: index, title: _data.name });
        }
      });
      this.setState({
        context: this.context.selectedContext,
        stateOptions: statesArray,
      });

      let _filter = {
        contextId: this.context.selectedContextId,
      };
      this.context.updateContext([
        { key: "DropDownFilter", value: JSON.stringify(_filter) },
      ]);

      this.context.context.map((_context) => {
        if (_context.name.toLowerCase() === this.context.selectedContext) {
          let _dropDown = [];
          let _usdOptions = [];
          _context.dropDownList.map((_value) => {
            _dropDown.push({
              id: _value.id,
              title: _value.name,
              city: _value.city,
            });
          });
          let dropDownConfig = JSON.parse(_context.dropDownConfig);
          let _dropdownState = {};
          dropDownConfig.map((_config) => {
            _dropdownState[_config.value] = _config.isVisible;
          });

          if (
            _context.name === Constants.DROPDOWN_DISPLAY_NAME.USD.toLowerCase()
          ) {
            _context.dropDownList.map((_data) => {
              _usdOptions.push({ id: _data.id, title: _data.name });
            });
            if (_usdOptions.length === 1) {
              this.setState({ selectedUSD: _usdOptions[0] });
              this.context.updateContext([
                { key: "selectedUSD", value: _usdOptions[0] },
              ]);
              this.saveUsdToFilterContext({
                _usdOptions: _usdOptions[0],
                _context: _context,
              });
              let _filter = [];

              _filter.push(_usdOptions[0].id.toString());
              this.handleUSDcallForOrganization(_filter);
            }
          }
          if (
            _context.name ===
            Constants.DROPDOWN_DISPLAY_NAME.ORGANIZATION.toLowerCase()
          ) {
            _context.dropDownList.map((_value) => {
              OrgList.push({
                id: _value.id,
                title: _value.name,
                city: _value.city,
              });
            });
            if (OrgList.length === 1) {
              this.setState({ selectedOrganization: OrgList[0] });
              this.saveOrganizationToFilterContext({
                OrgList: OrgList[0],
                _context: _context,
              });
              this.context.updateContext([
                { key: "ORGID", value: OrgList[0].id },
                {
                  key: "selectedOrganization",
                  value: OrgList[0],
                },
              ]);
            }
          }
          if (
            _context.name === Constants.DROPDOWN_DISPLAY_NAME.CLUB.toLowerCase()
          ) {
            let filterArray = [];
            if (_context.organizationList.length === 1) {
              this.setState({
                selectedOrganization: {
                  id: _context.organizationList[0].id,
                  title: _context.organizationList[0].name,
                  city: _context.organizationList[0].city,
                },
              });
              this.context.updateContext([
                { key: "ORGID", value: _context.organizationList[0].id },
                {
                  key: "selectedOrganization",
                  value: this.state.selectedOrganization,
                },
              ]);
              this.saveOrganizationToFilterContext({
                OrgList: _context.organizationList[0],
                _context: _context,
              });
              filterArray.push(_context.organizationList[0].id);
              this.clubNetworkCallOnOganizationSelection(filterArray);
            }
            _context.organizationList.map((_value) => {
              OrgList.push({
                id: _value.id,
                title: _value.name,
                city: _value.city,
              });
            });
          }

          this.setState({
            usdOptions:
              _usdOptions.length > 0 ? _usdOptions : this.state.usdOptions,
            dropdownPermission: _dropdownState,
            selectedContextObject: _context,
            assignedDropDownFormContext: OrgList.length > 0 ? OrgList : [],
          });
        }
      });
    } catch (error) {
      console.info(error);
    }
  };
  /**
   * This function will trigger after the component is updated
   * It stores the selected value of the filter and the selected context into the state variable
   * @param{Object} prevProps
   * It gives the value of props before the component is updated
   * @param{Object} prevState
   * It gives the value of state object before the component is updated
   * @param{Object} snapshot
   * @returns{null}
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    try {
      // if (prevProps.match.params !== this.props.match.params) {
      //   this.setState({ oldTimeValue: new Date().getTime() });
      // }
      if (this.state.context !== this.context.selectedContext) {
        if (this.context.resetValue) {
          this.setState({
            selectedState: { id: "", title: "All" },
            selectedUSD: { id: "", title: "All" },
            selectedOrganization: { id: "", title: "All" },
            selectedClub: { id: "", title: "All" },
          });
          this.context.updateContext([
            { key: "resetValue", value: false },
            { key: "isOrganizationSelected", value: false },
            {
              key: "selectedOrganization",
              value: "",
            },
            {
              key: "selectedClub",
              value: "",
            },
            {
              key: "selectedUSD",
              value: "",
            },
          ]);
        }
        let OrgList = [];
        let _usdOptions = [];

        _dropDownFilterForContext = {};
        this.context.context.map((_context) => {
          if (_context.name.toLowerCase() === this.context.selectedContext) {
            let _dropDown = [];
            _context.dropDownList.map((_value) => {
              _dropDown.push({
                id: _value.id,
                title: _value.name,
                city: _value.city,
              });
            });
            let dropDownConfig = JSON.parse(_context.dropDownConfig);
            let _dropdownState = {};
            dropDownConfig.map((_config) => {
              _dropdownState[_config.value] = _config.isVisible;
            });
            if (
              _context.name ===
              Constants.DROPDOWN_DISPLAY_NAME.USD.toLowerCase()
            ) {
              _context.dropDownList.map((_data) => {
                _usdOptions.push({ id: _data.id, title: _data.name });
              });
              if (_usdOptions.length === 1) {
                this.setState({ selectedUSD: _usdOptions[0] });
                this.context.updateContext([
                  { key: "selectedUSD", value: _usdOptions[0] },
                ]);
                this.saveUsdToFilterContext({ _usdOptions: _usdOptions[0] });

                let _filter = [];

                _filter.push(_usdOptions[0].id.toString());
                this.handleUSDcallForOrganization(_filter);
              }
            }
            if (
              _context.name ===
              Constants.DROPDOWN_DISPLAY_NAME.ORGANIZATION.toLowerCase()
            ) {
              _context.dropDownList.map((_value) => {
                OrgList.push({
                  id: _value.id,
                  title: _value.name,
                  city: _value.city,
                });
              });
              if (OrgList.length === 1) {
                this.setState({ selectedOrganization: OrgList[0] });
                this.context.updateContext([
                  { key: "ORGID", value: OrgList[0].id },
                  {
                    key: "selectedOrganization",
                    value: OrgList[0],
                  },
                ]);
                this.saveOrganizationToFilterContext({ OrgList: OrgList[0] });
              }
            }
            if (
              _context.name ===
              Constants.DROPDOWN_DISPLAY_NAME.CLUB.toLowerCase()
            ) {
              let filterArray = [];
              if (_context.organizationList.length === 1) {
                this.setState({
                  selectedOrganization: {
                    id: _context.organizationList[0].id,
                    title: _context.organizationList[0].name,
                    city: _context.organizationList[0].city,
                  },
                });

                this.context.updateContext([
                  {
                    key: "selectedOrganization",
                    value: this.state.selectedOrganization,
                  },
                ]);
                this.saveOrganizationToFilterContext({
                  OrgList: _context.organizationList[0],
                  _context: _context,
                });
                filterArray.push(_context.organizationList[0].id);
                this.clubNetworkCallOnOganizationSelection(filterArray);
              }
              _context.organizationList.map((_value) => {
                OrgList.push({
                  id: _value.id,
                  title: _value.name,
                  city: _value.city,
                });

                this.context.updateContext([
                  { key: "ORGID", value: _value.id },
                ]);
              });
            }
            this.setState({
              usdOptions:
                _usdOptions.length > 0 ? _usdOptions : this.state.usdOptions,
              dropdownPermission: _dropdownState,
              selectedContextObject: _context,
              assignedDropDownFormContext: OrgList.length > 0 ? OrgList : [],
            });
          }
        });
        this.setState({
          context: this.context.selectedContext,
        });
        let _filter = {
          contextId: this.context.selectedContextId,
        };
        this.context.updateContext([
          { key: "DropDownFilter", value: JSON.stringify(_filter) },
        ]);
      }
    } catch (error) {}
  };
  /**
   * This function handles the skeleton loading for the fallback of Suspense
   * @param{null}
   * @returns{JSX}
   * return TableSkeleton component
   */
  loadingSkeleton = () => <TableSkeleton></TableSkeleton>;
  saveUsdToFilterContext = ({ _usdOptions, _context }) => {
    _dropDownFilterForContext = {};
    let dropdownPermission = JSON.parse(
      _context
        ? _context.dropDownConfig
        : this.state.selectedContextObject.dropDownConfig
    );
    dropdownPermission.map((_config) => {
      if (_config.value === "usd") {
        if (_config.isPrimary) {
          this.saveFilterToContext({
            primaryDropdown: _usdOptions.id.toString(),
            context: _context ? _context : this.state.selectedContextObject,
          });
        } else {
          this.saveFilterToContext({
            primaryDropdown: this.state.selectedState.title,
            org: "",
            usd: _usdOptions.id.toString(),
          });
        }
      }
    });
  };
  saveOrganizationToFilterContext = ({ OrgList, _context }) => {
    this.context.updateContext([
      { key: "isOrganizationSelected", value: true },
    ]);
    let dropdownPermission = JSON.parse(
      _context
        ? _context.dropDownConfig
        : this.state.selectedContextObject.dropDownConfig
    );
    dropdownPermission.map((_config) => {
      if (_config.value === "organization") {
        if (_config.isPrimary) {
          this.saveFilterToContext({
            primaryDropdown: OrgList.id.toString(),
            context: _context ? _context : this.state.selectedContextObject,
          });
        } else {
          this.saveFilterToContext({
            org: OrgList.id.toString(),
            context: _context ? _context : this.state.selectedContextObject,
          });
        }
      }
    });
  };
  saveStateToFilterContext = (values) => {
    _dropDownFilterForContext = {};
    let dropdownPermission = JSON.parse(
      this.state.selectedContextObject.dropDownConfig
    );
    dropdownPermission.map((_config) => {
      if (_config.value === "state") {
        if (_config.isPrimary) {
          this.saveFilterToContext({
            primaryDropdown: values.title.toString(),
          });
        }
      }
    });
  };
  handleStateFilterChange = (event, values) => {
    if (values) {
      this.setState({
        selectedState: values,
        selectedUSD: { id: "", title: "All" },
        selectedOrganization: { id: "", title: "All" },
        reset: false,
      });
      this.context.updateContext([
        {
          key: "selectedOrganization",
          value: "",
        },
        {
          key: "USDID",
          value: "",
        },
        {
          key: "selectedUSD",
          value: "",
        },
      ]);

      this.usdGetNetworkCall({
        state: values.title,
      }).then((_data) => {
        let _usdArrays = [];
        try {
          if (_data.data.data.length === 1) {
            this.setState({
              selectedUSD: {
                id: _data.data.data[0].id,
                title: _data.data.data[0].name,
              },
            });
            this.context.updateContext([
              { key: "selectedUSD", value: this.state.selectedUSD },
            ]);
          }
        } catch (error) {}

        _data.data.data.map((_usd) => {
          if (_usd.name != null && _usd.id != null) {
            _usdArrays.push({ id: _usd.id, title: _usd.name });
          }
        });

        if (_usdArrays.length === 1) {
          let _filter = [];
          _filter.push(_usdArrays[0].id.toString());

          this.handleUSDcallForOrganization(_filter);
          this.setState({ selectedUSD: _usdArrays[0] });
          this.context.updateContext([
            { key: "selectedUSD", value: _usdArrays[0] },
          ]);
          this.saveUsdToFilterContext({
            _usdOptions: _usdArrays[0],
          });
        }
        this.setState({ usdOptions: _usdArrays });

        this.saveStateToFilterContext(values);
      });
    }
  };
  handleUSDcallForOrganization = (_filter) => {
    StateAPI.endpoints.usd
      .getAll({
        config: {
          headers: {
            Authorization: "bearer " + localStorage.getItem("token"),
          },
          params: {
            usdIds: JSON.stringify(_filter),
          },
        },
      })
      .then((_org) => {
        let _options = [];
        try {
          if (_org.data.data.length === 1) {
            this.setState({
              selectedOrganization: {
                id: _org.data.data[0].id,
                title: _org.data.data[0].name,
                city: _org.data.data[0].city,
              },
            });
            this.context.updateContext([
              {
                key: "selectedOrganization",
                value: this.state.selectedOrganization,
              },
            ]);
          }
        } catch (error) {}

        _org.data.data.map((_org) => {
          _options.push({ id: _org.id, title: _org.name, city: _org.city });
        });
        if (_options.length === 1) {
          this.setState({ selectedOrganization: _options[0] });
          this.context.updateContext([
            { key: "ORGID", value: _options[0].id },
            {
              key: "selectedOrganization",
              value: _options[0],
            },
          ]);
          this.saveOrganizationToFilterContext({ OrgList: _options[0] });
        }
        this.setState({ organizationOptions: _options });
      });
  };

  handleUsdDropdownInputChange = (event, value) => {
    if (
      value == "All" &&
      this.context.selectedContextId == 1 &&
      this.state.selectedState.title != "All"
    ) {
      this.usdGetNetworkCall({
        state:
          this.state.selectedState.title == "All"
            ? ""
            : this.state.selectedState.title,
      }).then((_data) => {
        let _usdArrays = [];
        try {
          if (_data.data.data.length === 1) {
            this.setState({
              selectedUSD: {
                id: _data.data.data[0].id,
                title: _data.data.data[0].name,
              },
            });
            this.context.updateContext([
              { key: "selectedUSD", value: this.state.selectedUSD },
            ]);
          }
        } catch (error) {}

        _data.data.data.map((_usd) => {
          if (_usd.name != null && _usd.id != null) {
            _usdArrays.push({ id: _usd.id, title: _usd.name });
          }
        });

        if (_usdArrays.length === 1) {
          let _filter = [];
          _filter.push(_usdArrays[0].id.toString());

          this.handleUSDcallForOrganization(_filter);
          this.setState({ selectedUSD: _usdArrays[0] });
          this.context.updateContext([
            { key: "selectedUSD", value: _usdArrays[0] },
          ]);
          this.saveUsdToFilterContext({
            _usdOptions: _usdArrays[0],
          });
        }
        this.setState({ usdOptions: _usdArrays });

        this.saveStateToFilterContext(this.state.selectedState);
      });
    }

    if (value == "All" && this.context.selectedContextId == 2) {
      this.usdGetNetworkCall({
        filter: this.context.DropDownFilter,
        searchText: value == "All" ? "" : value,
      }).then((data) => {
        if (data.data.status === "success") {
          let _options = [];
          let _usds = data.data.data;
          _usds.map((usd) => {
            _options.push({ id: usd.id, title: usd.name });
          });
          this.setState({ usdOptions: _options });
        }
      });
    }

    if (value && event && event.nativeEvent.type == "input") {
      this.usdGetNetworkCall({
        filter: this.context.DropDownFilter,
        state:
          this.state.selectedState.title == "All"
            ? ""
            : this.state.selectedState.title,
        searchText: value == "All" ? "" : value,
      }).then((data) => {
        if (data.data.status === "success") {
          let _options = [];
          let _usds = data.data.data;
          _usds.map((usd) => {
            _options.push({ id: usd.id, title: usd.name });
          });
          this.setState({ usdOptions: _options });
        }
      });
    }
  };

  /**
   * @param {null}
   * This function makes API call to get organization list
   * @returns {Promise}
   */
  usdGetNetworkCall = (params) => {
    return USD_API.endpoints.usd.getAll({
      config: {
        headers: {
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        params: params,
      },
    });
  };

  handleUsdDropDownChange = (event, values) => {
    if (values) {
      this.setState({
        selectedUSD: values,
        selectedOrganization: { id: "", title: "All" },
        reset: false,
      });
      this.context.updateContext([
        { key: "USDID", value: values.id },
        {
          key: "selectedOrganization",
          value: "",
        },
        {
          key: "selectedUSD",
          value: values,
        },
      ]);
      let _filter = [];

      _filter.push(values.id.toString());
      this.handleUSDcallForOrganization(_filter);
      this.saveUsdToFilterContext({ _usdOptions: values });
    }
  };

  clubNetworkCallOnOganizationSelection = (filterArray) => {
    let clubOptions = [];
    ClubAPI.endpoints.organizationClub
      .getAll({
        config: {
          headers: {
            Authorization: "bearer " + localStorage.getItem("token"),
          },
          params: {
            filter: {
              contextId: this.context.selectedContextId,
              filterById: filterArray.toString(),
            },
          },
        },
      })
      .then((_data) => {
        if (_data.data.status === "success") {
          if (_data.data.data.records.length === 1) {
            this.setState({
              selectedClub: {
                id: _data.data.data.records[0].id,
                title: _data.data.data.records[0].name,
              },
            });
            this.context.updateContext([
              {
                key: "selectedClub",
                value: this.state.selectedClub,
              },
            ]);
            this.saveFilterToContext({
              club: _data.data.data.records[0].id.toString(),
            });
          }
          _data.data.data.records.map((_club) => {
            clubOptions.push({ id: _club.id, title: _club.name });
          });
          this.setState({ clubOptions: clubOptions });
        }
      });
  };

  handleOrganizationDropdownInputChange = (event, value) => {
    if (
      value == "All" &&
      this.context.selectedContextId == 2 &&
      this.context.selectedContext == this.state.context
    ) {
      let _filter = [];
      _filter.push(this.state.selectedUSD.id.toString());

      this.handleUSDcallForOrganization(_filter);
    }
    if (
      value == "All" &&
      (this.context.selectedContextId == 3 ||
        this.context.selectedContextId == 4)
    ) {
      this.organizationGetNetworkCall({
        filter: this.context.DropDownFilter,
        searchText: value == "All" ? "" : value,
      }).then((data) => {
        if (data.data.status === "success") {
          let _options = [];
          let _org = data.data.data.records;
          _org.map((org) => {
            _options.push({ id: org.id, title: org.name, city: org.city });
          });

          if (
            this.state.selectedContextObject.name ===
              Constants.DROPDOWN_DISPLAY_NAME.ORGANIZATION.toLowerCase() ||
            this.state.selectedContextObject.name ===
              Constants.DROPDOWN_DISPLAY_NAME.CLUB.toLowerCase()
          )
            this.setState({ assignedDropDownFormContext: _options });
          this.setState({ organizationOptions: _options });
        }
      });
    }

    if (
      value &&
      this.state.context == this.context.selectedContext &&
      event &&
      event.nativeEvent.type == "input"
    ) {
      this.organizationGetNetworkCall({
        filter: this.context.DropDownFilter,

        searchText: value == "All" ? "" : value,
      }).then((data) => {
        if (data.data.status === "success") {
          let _options = [];
          let _org = data.data.data.records;
          _org.map((org) => {
            _options.push({ id: org.id, title: org.name, city: org.city });
          });

          if (
            this.state.selectedContextObject.name ===
              Constants.DROPDOWN_DISPLAY_NAME.ORGANIZATION.toLowerCase() ||
            this.state.selectedContextObject.name ===
              Constants.DROPDOWN_DISPLAY_NAME.CLUB.toLowerCase()
          )
            this.setState({ assignedDropDownFormContext: _options });
          this.setState({ organizationOptions: _options });
        }
      });
    }
  };

  /**
   * @param {null}
   * This function makes API call to get organization list
   * @returns {Promise}
   */
  organizationGetNetworkCall = (params) => {
    return ORG_API.endpoints.organizations.getAll({
      config: {
        headers: {
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        params: params,
      },
    });
  };

  handleOrganizationDropdownChange = (event, values) => {
    if (values) {
      let id = values.id.toString();
      let filterArray = [];
      filterArray.push(id);
      this.context.updateContext([{ key: "ORGID", value: values.id }]);

      this.clubNetworkCallOnOganizationSelection(filterArray);
      this.setState({
        selectedOrganization: values,
        reset: false,
        selectedClub: { id: "", title: "All" },
      });
      this.context.updateContext([
        {
          key: "selectedOrganization",
          value: values,
        },
      ]);
      this.saveOrganizationToFilterContext({ OrgList: values });
    }
  };
  saveFilterToContext = ({ primaryDropdown, usd, org, context, club }) => {
    _dropDownFilterForContext.contextId = context
      ? context.id.toString()
      : this.state.selectedContextObject.id.toString();
    if (primaryDropdown) {
      _dropDownFilterForContext.filterById = primaryDropdown.toString();
    }
    if (usd) {
      _dropDownFilterForContext.usdIds = [usd];
    }
    if (org) {
      _dropDownFilterForContext.orgIds = [org];
    }
    if (club) {
      _dropDownFilterForContext.clubIds = [club];
    } else {
      _dropDownFilterForContext.clubIds = [];
    }

    this.context.updateContext([
      {
        key: "DropDownFilter",
        value: JSON.stringify(_dropDownFilterForContext),
      },
    ]);
  };
  handleClubDropDownChange = (event, values) => {
    if (values) {
      this.saveFilterToContext({ club: values.id.toString() });
      this.setState({ selectedClub: values, reset: false });
      this.context.updateContext([{ key: "selectedClub", value: values }]);
    }
  };
  resetDropdown = () => {
    if (!this.state.reset) {
      this.setState({
        selectedState: { id: "", title: "All" },
        selectedUSD: { id: "", title: "All" },
        selectedOrganization: { id: "", title: "All" },
        selectedClub: { id: "", title: "All" },
        reset: true,
      });
      this.context.updateContext([
        { key: "selectedContextId", value: this.context.selectedContextId },
        { key: "isOrganizationSelected", value: false },

        { key: "ORGID", value: "" },
        { key: "USDID", value: "" },
        {
          key: "DropDownFilter",
          value: JSON.stringify({
            contextId: this.state.selectedContextObject.id.toString(),
          }),
        },
        {
          key: "selectedOrganization",
          value: "",
        },
        { key: "selectedClub", value: "" },
        { key: "selectedUSD", value: "" },
      ]);
      this.organizationGetNetworkCall({
        filter: this.context.DropDownFilter,
        searchText: "",
      }).then((data) => {
        if (data.data.status === "success") {
          let _options = [];
          let _org = data.data.data.records;
          _org.map((org) => {
            _options.push({ id: org.id, title: org.name, city: org.city });
          });
          if (
            _options.length === 1 &&
            this.state.selectedOrganization.title === "All"
          ) {
            let filterArray = [];

            this.setState({ selectedOrganization: _options[0] });
            this.context.updateContext([
              { key: "ORGID", value: _options[0].id },
              {
                key: "selectedOrganization",
                value: _options[0],
              },
            ]);

            this.saveOrganizationToFilterContext({ OrgList: _options[0] });
            filterArray.push(_options[0].id);
            this.clubNetworkCallOnOganizationSelection(filterArray);
          }

          if (
            this.state.selectedContextObject.name ===
              Constants.DROPDOWN_DISPLAY_NAME.ORGANIZATION.toLowerCase() ||
            this.state.selectedContextObject.name ===
              Constants.DROPDOWN_DISPLAY_NAME.CLUB.toLowerCase()
          )
            this.setState({ assignedDropDownFormContext: _options });
          this.setState({ organizationOptions: _options });
        }
      });

      this.usdGetNetworkCall({
        filter: this.context.DropDownFilter,
        searchText: "",
      }).then((data) => {
        if (data.data.status === "success") {
          let _options = [];
          let _usds = data.data.data;
          _usds.map((usd) => {
            _options.push({ id: usd.id, title: usd.name });
          });
          if (_options.length === 1 && this.state.selectedUSD.title == "All") {
            let _filter = [];

            _filter.push(_options[0].id.toString());
            this.handleUSDcallForOrganization(_filter);
            this.setState({ selectedUSD: _options[0] });
            this.context.updateContext([
              {
                key: "selectedUSD",
                value: _options[0],
              },
            ]);
          }
          this.setState({ usdOptions: _options });
        }
      });
    }
  };
  // onUserNavigate = () => {
  //   console.log("onUserNavigate called at", new Date().getTime());
  //   if (
  //     Math.abs(this.state.oldTimeValue - new Date().getTime()) >
  //     Constants.ALLOWED_IDLE_TIME
  //   ) {
  //     this.setState({ oldTimeValue: new Date().getTime() }, () => {
  //       localStorage.removeItem("token");
  //       window.location.reload();
  //     });

  //     //;
  //   }
  // };

  UNSAFE_componentWillMount = () => {
    this.context.updateContext([{ key: "USDID", value: "" }]);
  };
  render() {
    return (
      <>
        <div className="mainContent">
          <div className="app">
            <AppHeader className="topHeader" fixed>
              <Suspense fallback={this.loadingSkeleton()}>
                <Header />
              </Suspense>
            </AppHeader>
            <div className="app-body">
              {this.context.showSideBar ? (
                <AppSidebar fixed display="lg">
                  <div class="contextFilter d-md-block d-lg-none">
                    <Input
                      type="select"
                      name="context-filter"
                      id="context-filter"
                    >
                      <option value="Admin">Admin</option>
                      <option value="USD">USD</option>
                      <option value="Organization">Organization</option>
                      <option value="Club">Club</option>
                    </Input>
                  </div>
                  <AppSidebarHeader />
                  <AppSidebarForm />
                  <Suspense>
                    <AppSidebarNav
                      navConfig={
                        this.context.nav
                          ? { items: this.context.nav }
                          : { items: [] }
                      }
                      {...this.props}
                    />
                  </Suspense>
                  <AppSidebarFooter />
                  <AppSidebarMinimizer />
                </AppSidebar>
              ) : (
                ""
              )}

              <main
                className={
                  this.context.showSideBar ? "main" : "main no-sidebar"
                }
              >
                {this.context.hideGlobalFilter ||
                window.location.pathname.split("/")[1] == "profile" ? (
                  ""
                ) : (
                  <>
                    {this.context.showSideBar ? (
                      <div className="dropdownForTabs d-flex align-items-center w-100">
                        <FormGroup className="col-12 col-sm-12 col-md-12 col-lg-12 float-left m-0 text-right">
                          {this.state.dropdownPermission ? (
                            this.state.dropdownPermission.state ? (
                              <FormGroup className="d-inline-flex align-items-center m-0 text-right">
                                <Label
                                  htmlFor="select-tab"
                                  className="d-inline-block mr-2 mb-0"
                                >
                                  {Constants.DROPDOWN_DISPLAY_NAME.STATE}
                                </Label>
                                <Autocomplete
                                  key={this.context.selectedContextId}
                                  className="usdDropdown"
                                  id="combo"
                                  options={
                                    this.state.stateOptions
                                      ? this.state.stateOptions
                                      : []
                                  }
                                  value={this.state.selectedState}
                                  disableClearable={true}
                                  loadingText={"No options available"}
                                  noOptionsText={
                                    "No " +
                                    Constants.DROPDOWN_DISPLAY_NAME.STATE
                                  }
                                  disabled={
                                    this.state.stateOptions
                                      ? this.state.stateOptions.length === 0
                                        ? true
                                        : this.context.disableState
                                        ? this.context.disableState
                                        : false
                                      : Constants._DEFAULT_STRING
                                  }
                                  onChange={this.handleStateFilterChange}
                                  getOptionLabel={(option) => option.title}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </FormGroup>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          {this.state.dropdownPermission ? (
                            this.state.dropdownPermission.usd ? (
                              <FormGroup className="d-inline-flex align-items-center m-0 text-right">
                                <Label
                                  htmlFor="select-tab"
                                  className="d-inline-block mr-2 mb-0"
                                >
                                  {Constants.DROPDOWN_DISPLAY_NAME.USD}
                                </Label>

                                <Autocomplete
                                  disabled={
                                    this.context.disableUsd ||
                                    ((this.context.selectedContextId == 1 ||
                                      this.context.selectedContextId == "1") &&
                                      this.state.selectedState.id === "")
                                      ? true
                                      : false
                                  }
                                  key={this.context.selectedContextId}
                                  className="usdDropdown"
                                  id="combo-box"
                                  options={
                                    this.state.usdOptions
                                      ? this.state.usdOptions
                                      : []
                                  }
                                  noOptionsText={
                                    "NO " + Constants.DROPDOWN_DISPLAY_NAME.USD
                                  }
                                  disableClearable={true}
                                  value={this.state.selectedUSD}
                                  loadingText={"No options available"}
                                  onChange={this.handleUsdDropDownChange}
                                  onInputChange={
                                    this.handleUsdDropdownInputChange
                                  }
                                  getOptionLabel={(option) => option.title}
                                  renderOption={(option) => (
                                    <>
                                      {this.context.selectedContextId == "1" &&
                                      this.state.usdOptions.length > 1 &&
                                      option.title ==
                                        "Non Profit Organization" ? (
                                        <div className="divContainer">
                                          <Label>{option.title}</Label>
                                          <hr className="divider" />
                                        </div>
                                      ) : (
                                        option.title
                                      )}
                                    </>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </FormGroup>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          {this.state.dropdownPermission ? (
                            this.state.dropdownPermission.organization ? (
                              <FormGroup className="d-inline-flex align-items-center m-0 text-right">
                                {" "}
                                <Label
                                  htmlFor="select-tab"
                                  className="d-inline-block mr-2 mb-0"
                                >
                                  {Constants.DROPDOWN_DISPLAY_NAME.ORGANIZATION}
                                </Label>
                                <Autocomplete
                                  disabled={
                                    (this.context.disableOrg
                                      ? this.context.disableOrg
                                      : false) ||
                                    (this.state.selectedUSD.id == "" &&
                                      this.context.selectedContextId == "1") ||
                                    (this.state.selectedUSD.id == "" &&
                                      this.context.selectedContextId == "2")
                                  }
                                  key={this.context.selectedContextId}
                                  className="usdDropdown"
                                  id="combo-demo"
                                  options={
                                    this.state.selectedContextObject.name ===
                                      Constants.DROPDOWN_DISPLAY_NAME.ORGANIZATION.toLowerCase() ||
                                    this.state.selectedContextObject.name ===
                                      Constants.DROPDOWN_DISPLAY_NAME.CLUB.toLowerCase()
                                      ? this.state.assignedDropDownFormContext
                                        ? this.state.assignedDropDownFormContext
                                        : []
                                      : this.state.organizationOptions
                                      ? this.state.organizationOptions
                                      : []
                                  }
                                  disableClearable={true}
                                  loadingText={"No options available"}
                                  noOptionsText={
                                    "No " +
                                    Constants.DROPDOWN_DISPLAY_NAME.ORGANIZATION
                                  }
                                  value={this.state.selectedOrganization}
                                  onChange={
                                    this.handleOrganizationDropdownChange
                                  }
                                  onInputChange={
                                    this.handleOrganizationDropdownInputChange
                                  }
                                  getOptionLabel={(option) => option.title}
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      {option.city ? (
                                        <span>
                                          {option.title} ({option.city})
                                        </span>
                                      ) : (
                                        option.title
                                      )}
                                    </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </FormGroup>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          {this.state.dropdownPermission ? (
                            this.state.dropdownPermission.club ? (
                              <FormGroup className="d-inline-flex align-items-center m-0 text-right">
                                {" "}
                                <Label
                                  htmlFor="select-tab"
                                  className="d-inline-block mr-2 mb-0"
                                >
                                  {Constants.DROPDOWN_DISPLAY_NAME.CLUB}
                                </Label>
                                <Autocomplete
                                  key={this.context.selectedContextId}
                                  className="usdDropdown"
                                  id="combo"
                                  options={
                                    this.state.clubOptions
                                      ? this.state.clubOptions
                                      : []
                                  }
                                  value={
                                    this.state.selectedClub
                                      ? this.state.selectedClub
                                      : {}
                                  }
                                  disableClearable={true}
                                  loadingText={"No options available"}
                                  noOptionsText={
                                    "No " + Constants.DROPDOWN_DISPLAY_NAME.CLUB
                                  }
                                  disabled={
                                    (this.context.disableClub
                                      ? this.context.disableClub
                                      : false) ||
                                    (this.state.selectedOrganization.id == "" &&
                                      this.context.selectedContextId == "4")
                                  }
                                  onChange={this.handleClubDropDownChange}
                                  getOptionLabel={(option) => option.title}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </FormGroup>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          <FormGroup className="d-inline-flex align-items-center m-0 text-right">
                            <Button
                              outline
                              disabled={
                                this.context.disableOrg ||
                                this.context.disableState ||
                                (this.context.selectedContextId == 1 &&
                                  this.state.selectedState.title == "All") ||
                                (this.context.selectedContextId == 2 &&
                                  this.state.selectedUSD.title == "All") ||
                                (this.context.selectedContextId == 3 &&
                                  this.state.selectedOrganization.title ==
                                    "All") ||
                                (this.context.selectedContextId == 4 &&
                                  this.state.selectedOrganization.title ==
                                    "All") ||
                                this.context.disableUsd
                                  ? true
                                  : false
                              }
                              id="reset"
                              onClick={this.resetDropdown}
                              color="primary"
                            >
                              Reset
                            </Button>
                          </FormGroup>
                        </FormGroup>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
                <Container
                  className={this.context.showSideBar ? "" : "nosidebar-main"}
                  fluid
                >
                  <div className="contentWrap mt-3">
                    <Suspense fallback={this.loadingSkeleton()}>
                      <Switch>
                        <Route
                          path={"/profile"}
                          exact
                          name={"profile"}
                          component={React.lazy(() =>
                            import("../ProfileDetail")
                          )}
                        />
                        <Route
                          path={"/"}
                          exact
                          name={"/"}
                          render={() => (
                            <Redirect
                              push
                              to={
                                "/" +
                                this.context.context[0].name +
                                "/dashboard"
                              }
                            />
                          )}
                        />

                        {this.context.allRoutes
                          ? this.context.allRoutes.map((data) => (
                              <Route
                                key={data.id}
                                path={data.path}
                                exact
                                name={data.name}
                                render={(props) => {
                                  // this.onUserNavigate();
                                  return (
                                    <data.component
                                      {...props}
                                      selectedValue={
                                        this.context.DropDownFilter
                                      }
                                    />
                                  );
                                }}
                              />
                            ))
                          : Constants._DEFAULT_STRING}
                        <Route
                          path={"*"}
                          exact
                          component={React.lazy(() => import("../NotFound"))}
                        />
                      </Switch>
                    </Suspense>
                  </div>
                </Container>
              </main>
            </div>
            <footer
              className={
                this.context.showSideBar
                  ? "app-footer"
                  : "app-footer no-sidebar-footer"
              }
            >
              <span>
                Powered by{" "}
                <a href="https://www.selectiva.org/">Selectiva.org</a>
              </span>
              <span className="ml-auto"></span>
            </footer>
          </div>
        </div>
      </>
    );
  }
}
Homepage.contextType = MyContext;
export default Homepage;
