import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

import Utils from "../../Utils/commonUtil";
import Constants from "../../Constants/Constants";
import API from "../../Services/Api";

import Background1 from "../Assets/loginImg1.png";
import Background2 from "../Assets/loginImg2.png";

const verifyOtpAPI = new API({ url: Constants.CONFIG.BASE_URL });
verifyOtpAPI.createEntity({
  name: Constants.ENTITIES.AUTH,
  uri: "auth/verifyotp",
});
const numberRegex = (Constants.REGEX_FOR_NUMBER = /^[0-9]+$/);

class PasswordVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      token: "",
      email: "",
      otp: "",
      id: "",
      redirectToLogin: false,
      isOtpValid: true,
      redirectToConfirmPassword: false,
      isSubmitDisabled: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      userId: this.props.location.state.userId,
      token: this.props.location.state.token,
      email: this.props.location.state.email,
      id: this.props.location.state.id,
    });
  };
  handleOtp = (e) => {
    if (e.target.value == "") {
      this.setState({ otp: Utils.leftTrim(e.target.value) });
    } else if (numberRegex.test(e.target.value)) {
      this.setState({ isOtpValid: true, otp: Utils.leftTrim(e.target.value) });
    }
  };

  redirectToLogin = () => {
    this.setState({ redirectToLogin: true });
  };
  networkCallForVerifyOTP = () => {
    this.setState({ isSubmitDisabled: true });
    let body = { id: this.state.id.toString() };
    body.userId = this.state.userId.toString();
    body.email = this.state.email.toString();
    body.otp = this.state.otp.toString();
    verifyOtpAPI.endpoints.auth
      .create(body, {
        config: {
          headers: { Authorization: "bearer " + this.state.token.toString() },
        },
      })
      .then((data) => {
        if (data.data.status === "success") {
          if (data.data.data.isValid) {
            this.setState({
              redirectToConfirmPassword: true,
              isSubmitDisabled: false,
            });
          }
          if (data.data.data.isValid === false) {
            this.setState({
              isOtpValid: false,
              errorMessage: "Enter a valid verification code",
              isSubmitDisabled: false,
            });
          }
        }
      });
  };
  verifyOtp = (e) => {
    e.preventDefault();
    if (this.state.otp === "") {
      this.setState({
        isOtpValid: false,
        errorMessage: "Enter verification code",
      });
    } else {
      this.networkCallForVerifyOTP();
    }
  };

  render() {
    if (this.state.redirectToLogin) {
      return <Redirect push to="/"></Redirect>;
    }
    if (this.state.redirectToConfirmPassword) {
      return (
        <Redirect
          push
          to={{
            pathname: "/resetpassword",
            state: {
              userId: this.state.userId,
              token: this.state.token,
              email: this.state.email,
              id: this.state.id,
            },
          }}
        ></Redirect>
      );
    }
    return (
      <div
        className="bgLight"
        style={{
          backgroundImage: `url(${Background1}),url(${Background2})`,
        }}
      >
        <div className="app flex-row align-items-center">
          <Container>
            <Col md="12" className="text-center">
              <img
                src="../../../../selectivaorgfinallogo.png"
                style={{ maxWidth: "200px", margin: "40px 0" }}
              ></img>
            </Col>
            <Row className="justify-content-center">
              <Col md="5">
                <CardGroup>
                  <Card className="p-4 cardShadow">
                    <CardBody>
                      <Form onSubmit={this.verifyOtp}>
                        <h3 className="mb-3">Verification Code</h3>

                        <InputGroup className="mb-1">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock-open icons"></i>
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            type="text"
                            placeholder="Enter verification code"
                            autoComplete="verification"
                            onChange={this.handleOtp}
                            value={this.state.otp}
                            className={
                              !this.state.isOtpValid ? "is-invalid" : ""
                            }
                          />
                        </InputGroup>
                        {!this.state.isOtpValid ? (
                          <p
                            className="invalid-feedback d-block"
                            style={{ fontSize: "14px", marginLeft: "38px" }}
                          >
                            {this.state.errorMessage}
                          </p>
                        ) : (
                          ""
                        )}

                        <Row className="mt-4">
                          <Col xs="6">
                            <Button
                              outline
                              size="sm"
                              color="primary"
                              onClick={this.redirectToLogin}
                              disabled={
                                this.state.isSubmitDisabled ? true : false
                              }
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs="6" className="text-right">
                            <Button
                              inline
                              color="primary"
                              size="sm"
                              className="font-weight-medium"
                              disabled={
                                this.state.isSubmitDisabled ? true : false
                              }
                            >
                              {this.state.isSubmitDisabled ? (
                                <span
                                  style={{ marginRight: "5px" }}
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                              Next
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default PasswordVerification;
