import React from "react";
import Homepage from "./Components/Homepage";
import Login from "./Components/Login";
import ForgotPassword from "./Components/ForgotPassword";
import PasswordVerification from "./Components/PasswordVerification";
import ConfirmPassword from "./Components/ConfirmPassword";
import ContactAuth from "./Components/ContactAuth";
import "./App.scss";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import API from "../src/Services/Api";
import Constants from "../src/Constants/Constants";
import MyContext from "./Context/Context";
import SomethingWentWrong from "./Components/SomethingWentWrong";
import NotFound from "./Components/NotFound";
import { Modal, ModalBody } from "reactstrap";

const UserPermissionAPI = new API({ url: Constants.CONFIG.BASE_URL });
UserPermissionAPI.createEntity({
  name: Constants.ENTITIES.USER_PERMISSION,
  uri: "auth/userPermission",
});
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      token: localStorage.getItem("token"),
      success: false,
      permission: [],
      exception: false,
      modal: false,
      miniTimer: 20,
    };
  }
  toggle = () => this.setState({ modal: !this.state.modal });
  UNSAFE_componentWillMount = () => {
    this.idleTimer();
    if (this.state.token !== "") {
      this.NetworkCall();
    }
  };
  dataPreparationForNwtworkCall = (_data) => {
    var contextFromUrl =
      Constants.CONTEXT_ROUTE_MAPPING[window.location.pathname.split("/")[1]];
    let _selectedContext = _data.context.filter(
      (_context) => _context.name === contextFromUrl
    );
    _data.selectedContext =
      _selectedContext.length > 0
        ? _selectedContext[0].name.toLowerCase().replace(" ", "-")
        : _data.context[0].name.toLowerCase().replace(" ", "-");
    _data.selectedContextId =
      _selectedContext.length > 0
        ? _selectedContext[0].id
        : _data.context[0].id;
    _data.DropDownFilter = "";
    _data.ORGID = "";
    _data.UserInfo = _data.user;
    _data.updateContext = (_newValue) => {
      let _permissionCopy = this.state.permission;
      if (Array.isArray(_newValue)) {
        _newValue.map((_data) => {
          _permissionCopy[_data.key] = _data.value;
        });
        this.setState({
          permission: {
            ...this.state.permission,
          },
        });
      } else {
        this.setState({
          permission: _permissionCopy,
        });
      }
    };
    _data.showSideBar = true;
    _data.campaignVariables = [];
    _data.context.map((_context, index) => {
      _data.context[index].name = _context.name.toLowerCase();
    });
    this.setState({ permission: _data, success: true });
  };
  NetworkCall = () => {
    if (localStorage.getItem("token")) {
      UserPermissionAPI.endpoints.permission
        .getAll({
          config: {
            headers: {
              Authorization: "bearer " + localStorage.getItem("token"),
            },
          },
        })
        .then((data) => {
          try {
            if (data.data.status === "fail") {
              localStorage.removeItem("token");
            } else {
              this.dataPreparationForNwtworkCall(data.data.data);
            }
          } catch (error) {
            this.setState({ exception: true });
          }
        });
    }
  };

  logoutUser = () => {
    this.toggle();
    this.setState({ modal: false });
    localStorage.removeItem("token");
    window.location.reload();
  };

  keepMeLoggedIn = () => {
    this.setState({ modal: false });
    window.location.reload();
  };
  idleTimer = () => {
    if (this.state.modal === true) {
      this.setState({ modal: false });
    }
    var t;
    window.onmousemove = resetTimer; // catches mouse movements
    window.onmousedown = resetTimer; // catches mouse movements
    window.onclick = resetTimer; // catches mouse clicks
    window.onscroll = resetTimer; // catches scrolling
    window.onkeypress = resetTimer; //catches keyboard actions
    let logout = () => {
      if (localStorage.getItem("token")) {
        this.setState({ modal: true });
        setTimeout(() => {
          this.logoutUser();
        }, 20000);
      }
    };
    // window.location.href = "/"; //Adapt to actual logout script
    // function reload() {
    //   window.location.reload(); //Reloads the current page
    // }

    function resetTimer() {
      clearTimeout(t);
      t = setTimeout(logout, Constants.SESSION_TIMEOUT_TIME.logout); // time is in milliseconds (1000 is 1 second)
      // t = setTimeout(reload, Constants.SESSION_TIMEOUT_TIME.reload); // time is in milliseconds (1000 is 1 second)
    }
  };

  checkAuth = (val) => {
    this.setState({
      UserInfo: val,
      auth: true,
    });
    this.NetworkCall();
  };
  handleUserdata = (user) => {
    this.setState({
      permission: {
        ...this.state.permission,
        UserInfo: user,
      },
    });
  };
  render() {
    return (
      <>
        {this.state.exception ? (
          <SomethingWentWrong></SomethingWentWrong>
        ) : (
          <>
            <Router>
              <Switch>
                <Route
                  path={"/forgotpassword"}
                  name={"forgotpassword"}
                  render={(props) => <ForgotPassword {...props} />}
                />
                <Route
                  path={"/verifyotp"}
                  name={"verifyotp"}
                  render={(props) => <PasswordVerification {...props} />}
                />
                <Route
                  path={"/resetpassword"}
                  name={"resetpassword"}
                  render={(props) => <ConfirmPassword {...props} />}
                />
                <Route
                  path={"/importProvider"}
                  name={"ContactAuth"}
                  render={(props) =>
                    this.state.auth || this.state.token ? (
                      this.state.success ? (
                        <MyContext.Provider value={this.state.permission}>
                          <ContactAuth {...props} />
                        </MyContext.Provider>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  }
                />
                <Route
                  path="/notfound"
                  render={(props) => <NotFound {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) =>
                    this.state.auth || this.state.token ? (
                      this.state.success ? (
                        <MyContext.Provider value={this.state.permission}>
                          <Homepage {...props} />
                        </MyContext.Provider>
                      ) : (
                        ""
                      )
                    ) : (
                      <Login
                        Auth={this.checkAuth}
                        sendPermission={(data) => {
                          this.setState({ permission: data });
                        }}
                        {...props}
                      />
                    )
                  }
                />
              </Switch>
            </Router>
            <div>
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className="modal-dialog-centered"
                backdrop="static"
              >
                <ModalBody className="confirmModelBody">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <h5 className="popupHeading">
                        Your session has timed out
                      </h5>
                      <p className="popupText">
                        You have been Inactive for too long, do you want to
                        continue your session? Session will automatically log
                        out after 20 seconds.
                      </p>
                    </div>
                  </div>
                  <div class="float-left pl-0 pr-0 col-md-12">
                    <div class="text-left float-left mt-3 pl-0 pr-0 col-12 col-sm-6 col-md-6">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm cacelYesBtn"
                        onClick={this.logoutUser}
                      >
                        Logout
                      </button>
                    </div>
                    <div class="text-right float-left mt-3 pr-0 pl-0 col-12 col-sm-6 col-md-6">
                      <button
                        type="button"
                        class="mr-3 btn btn-primary btn-sm cacelYesBtn"
                        onClick={this.keepMeLoggedIn}
                      >
                        Keep me logged in
                      </button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </>
        )}
      </>
    );
  }
}

export default App;
