// import Constants from "../Constants/Constants";
var moment = require("moment");
var timePST = require("moment-timezone");
var Constants = require("../Constants/Constants");
var toast = require("react-toastify");
require("react-toastify/dist/ReactToastify.css");
const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
exports.showInLog = (data) => {
  if (Constants.DEBUG === true) console.log(data);
};
exports.dateFormat = (DateObject) => {
  let formatedDate = moment(DateObject).format("MM-DD-YYYY" /*"MMM D, YYYY" */);
  return formatedDate;
};
exports.dateTimeFormat = (timeDateObj) => {
  let getTime = timeDateObj.split(",")[2];
  let _temp1 = getTime.split(":");
  let _temp2 = _temp1[2].split(" ");
  let formatedTime = _temp1[0] + ":" + _temp1[1] + " " + _temp2[1];
  return formatedTime;
};
exports.generatePassword = (length) => {
  var result = "";
  var characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*()_+<>/";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
exports.checkImageSizeValidation = (image, height, width) => {
  var _URL = window.URL || window.webkitURL;
  var img;
  var status;
  if (image) {
    img = new Image();
    var imagePromise = new Promise((resolve, reject) => {
      img.onload = function () {
        if (this.width <= width && this.height <= height) {
          status = true;
        } else {
          status = false;
        }
        resolve(status);
      };
      img.src = _URL.createObjectURL(image);
    });
  }
  return imagePromise;
};
exports.checkImageType = (file) => {
  let fileType = ["image/png", "image/jpg", "image/jpeg"];
  let found;
  if (fileType.includes(file.type)) {
    found = true;
  } else {
    found = false;
  }
  return found;
};
/* exports.dateFormat = DateObject => {
  let formatedDate = moment(DateObject).format("MMM D, YYYY");
  return formatedDate;
}; */
exports.scrollToTop = () => {
  window.scroll(0, 0);
};

exports.removeSpaceFromInput = (input) => {
  return input.trim();
};

exports.toTitleCase = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};
exports.covertStringFirstLetter = (text) => {
  return text.toLowerCase().charAt(0).toUpperCase() + text.substring(1);
};
exports.leftTrim = (input) => {
  return input.replace(/^\s+/, "");
};

exports.showSuccessToast = (message, statusCode) => {
  statusCode = statusCode || "";
  toast.toast.configure({
    position: "top-right",
    autoClose: 5000,
    draggable: false,
    closeOnClick: true,
  });
  return toast.toast.success(message);
};

exports.showErrorToast = (message, statusCode) => {
  statusCode = statusCode || "";
  toast.toast.configure({
    position: "top-right",
    autoClose: 5000,
    draggable: false,
    closeOnClick: true,
  });
  return toast.toast.error(message);
};
exports.checkImageSize = (file) => {
  let fileSize = file.size / 1024 / 1024;
  if (fileSize > Constants.FILE_SIZE) {
    return false;
  } else {
    return true;
  }
};
exports.kFormatter = (num) => {
  return Math.abs(num) > 9999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : numberWithCommas(Math.sign(num) * Math.abs(num));
};
const numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
exports.numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
exports.validateCSVUpload = (file) => {
  try {
    let whiteListedExtension = ["xlsx", "csv", "xls"];
    let fileNameArray = file.name.split(".");
    let receivedExtension = fileNameArray[fileNameArray.length - 1];
    if (whiteListedExtension.includes(receivedExtension)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.info(error);
  }
};
exports.validationError = (title) => {
  if (title !== undefined && title !== "" && title !== null) {
    return true;
  } else {
    return false;
  }
};
exports.isErrorInTitle = (title) => {
  if (title === undefined || title.length === 0 || title.length > 100) {
    return true;
  } else {
    return false;
  }
};
exports.isClubNameValid = (title) => {
  if (title === undefined || title.length === 0 || title.length > 100) {
    return false;
  } else {
    return true;
  }
};
exports.completeDateFormat = (DateObject) => {
  let formatedDate = moment(DateObject).format("dddd, MMMM Do YYYY, h:mm:ss A");
  return formatedDate;
};
exports.generateCauseLink = (id, title, amount, cdsCode) => {
  return `${Constants.COPY_LINK_BASE_URL}${title
    .toLowerCase()
    .replace(/\s+/g, "-")}/${id}/${amount.replace("$", "")}/${cdsCode}`;
};
exports.generateNewCauseLink = (id, amount, causeSlug) => {
  return `${Constants.COPY_LINK_BASE_URL}?c_id=${id}&a_id=${amount.replace(
    "$",
    ""
  )}&c_slg=${causeSlug}`;
};
exports.completeDateFormatInPST = (DateObject) => {
  let formatedDate = timePST(DateObject)
    .tz("America/Los_Angeles")
    .format("dddd, MMMM Do YYYY, h:mm:ss A");
  return formatedDate;
};
exports.alphabetsRegex = (str) => {
  let regExp = /[^a-zA-Z]/g;
  let letters = str.replace(regExp, "");
  return letters;
};
exports.capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

exports.checkCsvFile = (file) => {
  try {
    let whiteListedExtension = ["csv"];
    let fileNameArray = file.name.split(".");
    let receivedExtension = fileNameArray[fileNameArray.length - 1];
    if (whiteListedExtension.includes(receivedExtension)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.info(error);
  }
};

exports.checkCsvSize = (file) => {
  let fileSize = file.size / 1024 / 1024;
  if (fileSize > Constants.CSV_FILE_SIZE) {
    return false;
  } else {
    return true;
  }
};

exports.stripHtml = (html) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};
exports.bytesConversion = (x) => {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  //include a decimal point and a tenths-place digit if presenting
  //less than ten of KB or greater units
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

exports.bankName = [
  {
    id: 1,
    bankName: "JPMorgan Chase & Co.",
  },
  {
    id: 2,
    bankName: "Bank of America Corp.",
  },
  {
    id: 3,
    bankName: "Wells Fargo & Co.",
  },
  {
    id: 4,
    bankName: "Citigroup Inc.",
  },
  {
    id: 5,
    bankName: "U.S. Bancorp",
  },
  {
    id: 6,
    bankName: "Truist Bank",
  },
];
exports.modeOfPayment = [
  {
    id: 1,
    modeOfPayment: "Visa",
  },
  {
    id: 2,
    modeOfPayment: "MasterCard",
  },
  {
    id: 3,
    modeOfPayment: "Discover",
  },
  {
    id: 4,
    modeOfPayment: "AmericanExpress",
  },
  {
    id: 5,
    modeOfPayment: "Paypal",
  },
];
const completeDateFormat = (DateObject) => {
  let formatedDate = moment(DateObject).format("dddd, MMMM Do YYYY, h:mm:ss A");
  return formatedDate;
};
const dateTimeFormat = (timeDateObj) => {
  let getTime = timeDateObj.split(",")[2];
  let _temp1 = getTime.split(":");
  let _temp2 = _temp1[2].split(" ");
  let formatedTime = _temp1[0] + ":" + _temp1[1] + " " + _temp2[1];
  return formatedTime;
};
const dateFormat = (DateObject) => {
  let formatedDate = moment(DateObject).format("MM-DD-YYYY" /*"MMM D, YYYY" */);
  return formatedDate;
};
exports.removeDashInDate = (date) => {
  let dateValue = completeDateFormat(date);
  let time = dateTimeFormat(dateValue);
  let dte = dateFormat(date) + "," + time;
  let updatedDate = dte.replace(/-/g, "/");
  return updatedDate;
};
