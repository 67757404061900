import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function TableSkeleton(props) {
  return (
    <div>
      {!props.onlyTable ? (
        <>
          <div style={{ float: "left", width: "100%" }}>
            <Skeleton
              variant="text"
              width={100}
              height={40}
              style={{ float: "left" }}
            />
            <Skeleton
              variant="text"
              width={135}
              height={55}
              style={{ float: "right" }}
            />
          </div>
          <div style={{ float: "left", width: "100%" }}>
            <Skeleton
              variant="text"
              width={145}
              height={60}
              style={{ float: "left" }}
            />
            <Skeleton
              variant="text"
              width={175}
              height={60}
              style={{ float: "right" }}
            />
          </div>
        </>
      ) : (
        ""
      )}

      <table style={{ width: "100%" }}>
        <tr>
          <th>
            <Skeleton variant="text" width={185} height={70} />
          </th>
          <th>
            <Skeleton variant="text" width={185} height={70} />
          </th>
          <th>
            <Skeleton variant="text" width={185} height={70} />
          </th>
          <th>
            <Skeleton variant="text" width={185} height={70} />
          </th>
          <th>
            <Skeleton variant="text" width={185} height={70} />
          </th>
        </tr>
        <tr>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
          <td>
            <Skeleton variant="text" width={185} height={50} />
          </td>
        </tr>
      </table>
    </div>
  );
}
export default TableSkeleton;
