import React, { useContext, useEffect } from "react";
import "./Header.css";
import { Nav, Navbar, NavbarBrand, NavItem } from "reactstrap";
import { AppSidebarToggler } from "@coreui/react";
import MyContext from "../../Context/Context";
import { Redirect } from "react-router-dom";
import Constants from "../../Constants/Constants";

var routingObject = [];
var tabArray = [];
var orgOptions = [];
/**
 * This component render the header of the application.
 * It contains the logic for dynamic routning and dynamic component import
 * It handles the context change for the application
 */
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      oldValue: Constants._DEFAULT_STRING,
    };
  }
  /**
   * This function is called just after the component is mounted.
   * This component created option for filter dropdown and store in the contextAPI
   * @param{null}
   * @returns{null}
   */
  componentDidMount = () => {
    window.onpopstate = () => {
      let value = window.location.pathname.split("/")[1];
      if (this.state.selectedContext !== this.context.selectedContext) {
        this.setState({ oldValue: value });
        try {
          let id = "";
          if (value == "admin") {
            id = "1";
          } else if (value == "usd") {
            id = "2";
          } else if (value == "organization") {
            id = "3";
          } else if (value == "club") {
            id = "4";
          }

          this.context.updateContext([
            { key: "selectedContext", value: value.replace(/\s+/g, "-") },
            { key: "selectedContextId", value: id },
            { key: "isOrganizationSelected", value: false },
            { key: "resetValue", value: true },
          ]);

          this.setState({
            newValueForTab: value,
            displayTabsFor: value,
            redirectToContextRoute: true,
          });
        } catch (error) {
          console.info(error);
        }
      }
    };

    let _selectedContext = window.location.pathname
      .split("/")[1]
      .replace("-", " ");
    this.setState({
      context: this.context.context,
      selectedContext:
        _selectedContext !== Constants._DEFAULT_STRING
          ? _selectedContext
          : this.context.selectedContext,
      displayTabsFor:
        this.state.displayTabsFor === undefined
          ? this.context.selectedContext.toLowerCase()
          : this.state.displayTabsFor.toLowerCase(),
    });
    let OptionsArray = [];
    let orgOptions = [];
    try {
      this.context.context.map((_context) => {
        if (_context.name.toLowerCase() === this.context.selectedContext) {
          if (_context.hasDropDown) {
            _context.dropDownList.map((_item) => {
              if (_item !== null) {
                OptionsArray.push({
                  title: _item.name.toString(),
                  id: _item.id.toString(),
                  city: _item.city,
                });
              }
            });
            if (
              _context.organizationList
                ? _context.organizationList.length > 0
                : Constants._DEFAULT_STRING
            ) {
              _context.organizationList.map((_data) => {
                orgOptions.push({
                  title: _data.name,
                  id: _data.id,
                  city: _data.city,
                });
              });
            }
            this.context.updateContext([
              { key: "OptionsArray", value: OptionsArray },
              {
                key: "selectedValue",
                value: OptionsArray[0],
              },
              { key: "orgOptions", value: orgOptions },
              { key: "selectedOrgValue", value: orgOptions[0] },
            ]);
            let selectedDropDown = {};
            selectedDropDown.contextId =
              this.context.selectedContextId.toString();
            selectedDropDown.filterById =
              OptionsArray.length > 0
                ? OptionsArray[0].id.toString()
                : Constants._DEFAULT_STRING;
          } else {
            this.context.updateContext([
              { key: "OptionsArray", value: [] },
              {
                key: "selectedValue",
                value: [],
              },
            ]);
          }
        }
      });
    } catch (error) {
      this.setState({ exception: true });

      console.info(error);
    }
  };
  /**
   * This function is called everytime when the component is being update
   * It created options for filter dropdown when user select any new context
   * @param{Obejct} prevProps
   * It gives you the value of the props before update
   * @param{Object} prevState
   * It gives you the value of state object before update
   * @param{Object} snpshot
   * @returns{null}
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      prevState.displayTabsFor !== this.state.displayTabsFor ||
      prevState.displayTabsFor === undefined
    ) {
      if (
        this.state.displayTabsFor !== Constants.CONTEXTS.SELORG.toLowerCase()
      ) {
        let OptionsArray = [];
        let orgOptions = [];
        try {
          this.context.context.map((_context) => {
            if (_context.name.toLowerCase() === this.state.displayTabsFor) {
              if (_context.hasDropDown) {
                _context.dropDownList.map((_item) => {
                  if (_item !== null) {
                    OptionsArray.push({
                      title: _item.name.toString(),
                      id: _item.id.toString(),
                      city: _item.city,
                    });
                  }
                });
                if (_context.organizationList.length > 0) {
                  _context.organizationList.map((_data) => {
                    orgOptions.push({
                      title: _data.name,
                      id: _data.id,
                      city: _data.city,
                    });
                  });
                }
                this.context.updateContext([
                  { key: "OptionsArray", value: OptionsArray },
                  {
                    key: "selectedValue",
                    value: OptionsArray[0],
                  },
                  { key: "orgOptions", value: orgOptions },
                  { key: "selectedOrgValue", value: orgOptions[0] },
                ]);
                this.setState({
                  OptionsArray: OptionsArray,
                  selectedValue: OptionsArray[0],
                });
                let selectedDropDown = {};
                selectedDropDown.contextId =
                  this.context.selectedContextId.toString();
                selectedDropDown.filterById =
                  OptionsArray.length > 0
                    ? OptionsArray[0].id.toString()
                    : Constants._DEFAULT_STRING;
              } else {
                this.setState({
                  OptionsArray: [],
                  selectedValue: [],
                  selectedContext: this.context.selectedContext.toLowerCase(),
                });
                this.context.updateContext([
                  { key: "OptionsArray", value: [] },
                  {
                    key: "selectedValue",
                    value: [],
                  },
                ]);
              }
            }
          });
        } catch (error) {
          this.setState({ exception: true });
        }
      } else {
        let selectedDropDown = {};
        selectedDropDown.contextId = this.context.selectedContextId.toString();
        selectedDropDown.filterById = Constants._DEFAULT_STRING;
      }
    }
  };
  /**
   * This function handles the onChange event for Context displayed in this component
   * According to this selected context rounting and tab are displayed
   * @param{String} value
   * It gives the selected value of the context
   * @param{Object} event
   * It gives the current event object
   */
  handleContextChange = (value, event) => {
    this.setState({ oldValue: value });
    try {
      let _filter = {
        contextId: event.target.name,
        filterById:
          value === this.context.context[0].name ? "" : this.context.ORGID,
      };

      this.context.updateContext([
        { key: "selectedContext", value: value.replace(/\s+/g, "-") },
        { key: "selectedContextId", value: event.target.name },
        { key: "isOrganizationSelected", value: false },
        { key: "resetValue", value: true },
      ]);

      this.setState({
        newValueForTab: value,
        displayTabsFor: value,
        redirectToContextRoute: true,
      });
    } catch (error) {
      console.info(error);
    }
    this.context.updateContext([{ key: "ORGID", value: "" }]);
  };
  /**
   * It handles signing out from the application
   * @param{null}
   * @returns{null}
   */
  handleSignOut = () => {
    localStorage.removeItem("token");
    this.setState({ shouldRedirectToHome: true });
  };
  /**
   * It handles redirect to profile section .
   * @param{null}
   * @returns{null}
   */
  handleRedirectToProfile = () => {
    this.setState({ shouldRedirectToProfile: true });
  };
  render() {
    if (this.state.shouldRedirectToHome) {
      window.location.reload();
      return <Redirect push to="/"></Redirect>;
    }
    if (this.state.redirectToContextRoute) {
      return (
        <>
          <Header></Header>
          <Redirect
            push
            to={
              "/" +
              this.state.newValueForTab.toLowerCase().replace(/\s+/g, "-") +
              "/dashboard"
            }
          ></Redirect>
        </>
      );
    }

    if (this.state.shouldRedirectToProfile) {
      return (
        <>
          <Header></Header>
          <Redirect push to="/profile"></Redirect>
        </>
      );
    }
    return (
      <>
        <Routes
          displayTabsFor={this.state.displayTabsFor}
          tabsToDisplay={(data) => {
            this.context.updateContext([{ key: "nav", value: data }]);
          }}
          allRoutes={(allRoutes, tabName) => {
            if (this.state.oldTab !== tabName.toLowerCase()) {
              this.setState({ oldTab: tabName.toLowerCase() });
              this.context.updateContext([
                { key: "allRoutes", value: allRoutes },
              ]);
            }
          }}
        ></Routes>
        <div className="container-fluid">
          <Navbar
            className="navbar-expand-lg navbar-dark"
            style={{ width: "100%", height: "100%", padding: "0" }}
          >
            <AppSidebarToggler className="d-lg-none" display="md" mobile />
            <AppSidebarToggler className="d-md-down-none" display="lg" />
            <NavbarBrand
              style={{ width: "auto" }}
              href={
                ("/" + this.context.selectedContext.toLowerCase()).replace(
                  /\s+/g,
                  "-"
                ) + "/dashboard"
              }
            >
              <img
                alt={Constants._DEFAULT_STRING}
                src={"/selectivaWhiteLogo.png"}
              ></img>
            </NavbarBrand>

            {this.context.showSideBar ? (
              <>
                <Nav className="nav-Items d-md-down-none d-lg-flex" navbar>
                  <MyContext.Consumer>
                    {(permission) =>
                      permission
                        ? permission.context.map((data) => (
                            <NavItem className="px-3">
                              <button
                                onClick={(event) =>
                                  this.handleContextChange(data.name, event)
                                }
                                name={data.id}
                                className={
                                  data.name.replace(/\s+/g, "-") ===
                                  this.context.selectedContext
                                    ? "nav-link activeTab"
                                    : "nav-link"
                                }
                                id={data.name}
                              >
                                {data.displayName}
                              </button>
                            </NavItem>
                          ))
                        : Constants._DEFAULT_STRING
                    }
                  </MyContext.Consumer>
                </Nav>

                <Nav className="ml-auto" navbar>
                  <NavItem className="profileDropdown">
                    <div className="btn-group">
                      <span
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span
                          class="d-md-down-none"
                          style={{ color: "#fff", marginRight: "10px" }}
                        >
                          Hello!
                        </span>
                        <span className="dataLetters">
                          {this.context.UserInfo
                            ? this.context.UserInfo.firstName
                                .charAt(0)
                                .toUpperCase()
                            : Constants._DEFAULT_STRING}
                        </span>
                      </span>
                      <div className="dropdown-menu dropdown-menu-right">
                        <span className="dropdown-item profileName">
                          <span className="proDetailWrap">
                            <span className="dataLetters">
                              {this.context.UserInfo
                                ? this.context.UserInfo.firstName
                                    .charAt(0)
                                    .toUpperCase()
                                : Constants._DEFAULT_STRING}
                            </span>
                            <span
                              style={{
                                display: "inline-block",
                                width: "70%",
                                verticalAlign: "top",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {this.context.UserInfo
                                ? this.context.UserInfo.firstName +
                                  " " +
                                  this.context.UserInfo.lastName
                                : Constants._DEFAULT_STRING}
                              <span
                                style={{
                                  display: "block",
                                  fontSize: "10px",
                                  color: "#636363",
                                }}
                              >
                                {this.context.UserInfo
                                  ? this.context.UserInfo.role.name
                                  : Constants._DEFAULT_STRING}
                              </span>
                            </span>
                          </span>
                        </span>
                        <div className="dropdown-divider"></div>
                        <a
                          className="dropdown-item"
                          onClick={this.handleRedirectToProfile}
                        >
                          My Profile
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={this.handleSignOut}
                        >
                          Log Out
                        </a>
                      </div>
                    </div>
                  </NavItem>
                </Nav>
              </>
            ) : (
              <div className="campaign-title-nav">
                <span>
                  {this.context.camapignTitle
                    ? this.context.camapignTitle
                    : "Campaign Title"}
                </span>
              </div>
            )}
          </Navbar>
        </div>
      </>
    );
  }
}
Header.contextType = MyContext;
export default Header;
/**
 * This is a suppporting function based component which is used to create dynamic routes and displayig tabs dynamically
 * @param {Object} props
 * Props for the component
 */
function Routes(props) {
  const contex = useContext(MyContext);
  routingObject = [];
  useEffect(() => {
    tabArray = [];
    try {
      contex.context.map((_context) => {
        if (
          _context.name.toLowerCase().replace(/\s+/g, "-") ===
          contex.selectedContext
        ) {
          let childrenArray = [];
          _context.tabs.map((Iterator) => {
            if (Iterator.children.length !== 0) {
              childrenArray = [];
              Iterator.children.map((child) => {
                childrenArray.push({
                  id: child.id,
                  name: child.name,
                  icon: child.icon,
                  url: child.routes[0]
                    ? (
                        "/" +
                        contex.selectedContext.toLowerCase() +
                        child.routes[0].route
                      ).replace(/\s+/g, "-")
                    : Constants._DEFAULT_STRING,
                });
              });
              tabArray.push({
                id: Iterator.id,
                name: Iterator.name,
                url: Iterator.routes[0]
                  ? (
                      "/" +
                      contex.selectedContext.toLowerCase() +
                      Iterator.routes[0].route
                    ).replace(/\s+/g, "-")
                  : Constants._DEFAULT_STRING,
                icon: Iterator.icon,
                children: childrenArray,
              });
              //return null;
            } else {
              tabArray.push({
                id: Iterator.id,
                name: Iterator.name,
                url: Iterator.routes[0]
                  ? (
                      "/" +
                      contex.selectedContext.toLowerCase() +
                      Iterator.routes[0].route
                    ).replace(/\s+/g, "-")
                  : Constants._DEFAULT_STRING,
                icon: Iterator.icon,
              });
            }
          });

          props.tabsToDisplay(tabArray);
        }
      });
    } catch (error) {
      console.info(error);
    }
  }, [props.displayTabsFor]);
  if (contex.selectedContext) {
    try {
      contex.context.map((data) => {
        if (data.name.replace(/\s+/g, "-") === contex.selectedContext) {
          routingObject.push({
            path: ("/" + contex.selectedContext.toLowerCase()).replace(
              /\s+/g,
              "-"
            ),
            exact: true,
            activeClassName: "active",
            component: React.lazy(() =>
              data.tabs.length > 0 && data.tabs[0].routes.length > 0
                ? import("../" + data.tabs[0].routes[0].component)
                : import("../" + Constants.ERROR_COMPONENTS.ERROR_404)
            ),
          });

          data.tabs.map((route) => {
            route.routes.map((Iterator) => {
              routingObject.push({
                path: (
                  "/" +
                  contex.selectedContext.toLowerCase() +
                  Iterator.route
                ).replace(/\s+/g, "-"),
                exact: true,
                activeClassName: "active",
                component: React.lazy(() => import("../" + Iterator.component)),
              });
            });
            if (route.children.length !== 0) {
              route.children.map((child) => {
                child.routes.map((children) => {
                  routingObject.push({
                    path: (
                      "/" +
                      contex.selectedContext.toLowerCase() +
                      children.route
                    ).replace(/\s+/g, "-"),
                    exact: true,
                    activeClassName: "active",
                    component: React.lazy(() =>
                      import("../" + children.component)
                    ),
                  });
                });
                /* */
              });
            }
          });

          props.allRoutes(routingObject, data.name);
        }
      });
    } catch (error) {
      console.info(error);
    }
  }
  return null;
}
