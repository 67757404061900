import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Redirect, Switch, Route } from "react-router-dom";
import API from "../../Services/Api";
import Constants from "../../Constants/Constants";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  CardHeader,
  FormGroup,
  Label,
} from "reactstrap";
import ForgotPassword from "../ForgotPassword";
import PasswordVerification from "../PasswordVerification";
import Background1 from "../Assets/loginImg1.png";
import Background2 from "../Assets/loginImg2.png";
import utils from "../../Utils/commonUtil";
import ConfirmPassword from "../ConfirmPassword";
import { FormatColorResetSharp } from "@material-ui/icons";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import "./Login.css";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    maxWidth: 320,
    overflow: "hidden",
  },
}))(Tooltip);

const authAPI = new API({ url: Constants.CONFIG.BASE_URL });
authAPI.createEntity({ name: Constants.ENTITIES.AUTH, uri: "auth/user" });
const UserPermissionAPI = new API({ url: Constants.CONFIG.BASE_URL });
UserPermissionAPI.createEntity({
  name: Constants.ENTITIES.USER_PERMISSION,
  uri: "auth/userPermission",
});

class Login extends Component {
  state = {
    password: "",
    username: "",
    redirectToForgotPassword: "",
    isError: false,
    isUserNameValid: true,
    isPasswordValid: true,
    errorMessage: "",
    redirectToHome: false,
    inputType: "password",
    disableLoginBtn: false,
    isKeepMeSignedIn: true,
  };
  UNSAFE_componentWillMount = () => {
    if (localStorage.getItem("token") === null) {
      this.setState({ redirectToHome: true });
    }
  };
  passwordChange = (e) => {
    this.setState({
      isPasswordValid: true,
      errorMessage: "",
      disableLoginBtn: false,
      password: utils.leftTrim(e.target.value),
    });
  };
  usernameChange = (e) => {
    this.setState({
      isUserNameValid: true,
      disableLoginBtn: false,
      errorMessage: "",
      username: utils.leftTrim(e.target.value),
    });
  };
  networkCallforLogin = () => {
    var body = {
      email: this.state.username,
      password: this.state.password,
    };
    authAPI.endpoints.auth
      .create(body, {
        config: {},
      })
      .then((data) => {
        this.setState({ disableLoginBtn: false });
        if (data.data.data.token) {
          localStorage.setItem("token", data.data.data.token);
          this.props.Auth(data.data.data.user);
        } else {
          this.setState({
            isError: true,
          });
        }

        if (data.data.status === "fail") {
          if (data.data.message.message) {
            this.setState({
              errorMessage: data.data.message.message,
            });
          } else {
            this.setState({
              errorMessage: data.data.message,
            });
          }
        }
      });
  };

  login = (e) => {
    this.setState({ disableLoginBtn: true });
    e.preventDefault();

    let networkCall = true;
    if (this.state.username === "") {
      this.setState({
        isUserNameValid: false,
        errorMessage: "",
        disableLoginBtn: false,
      });
      networkCall = false;
    }
    if (this.state.password === "") {
      this.setState({ isPasswordValid: false, disableLoginBtn: false });
      networkCall = false;
    }

    if (networkCall) {
      this.networkCallforLogin();
    }
  };
  userPermissionNetworkCall = () => {
    UserPermissionAPI.endpoints.permission
      .getAll({
        config: {
          headers: {
            Authorization: "bearer " + localStorage.getItem("token"),
          },
        },
      })
      .then((data) => {
        this.props.sendPermission(data);
        localStorage.setItem("token", data.data.data.token);
        sessionStorage.setItem("token", data.data.data.token);
      });
  };

  redirectToForgotPassword = () => {
    this.setState({ redirectToForgotPassword: true });
  };
  togglePassword = () => {
    this.state.inputType === "password"
      ? this.setState({ inputType: "text" })
      : this.setState({ inputType: "password" });
    document.getElementById("loginBtn").focus();
  };
  handleKeepMeSignIn = () => {
    let value = this.state.isKeepMeSignedIn;
    this.setState({
      isKeepMeSignedIn: !value,
    });
  };
  handleKeyDown = (event) => {
    this.login();
  };
  render() {
    if (this.state.redirectToForgotPassword) {
      return <Redirect push to="/forgotpassword"></Redirect>;
    }
    if (this.state.redirectToHome) {
      this.setState({ redirectToHome: false });
      return <Redirect push to="/"></Redirect>;
    }
    const { classes } = this.props;
    return (
      <>
        <div
          className="bgLight"
          style={{
            backgroundImage: `url(${Background1}),url(${Background2})`,
          }}
        >
          <div className="app flex-row align-items-center ">
            <Container>
              <Col md="12" className="text-center">
                <img
                  src="../../../../selectivaorgfinallogo.png"
                  style={{ maxWidth: "200px", margin: "0px 0 30px" }}
                ></img>
              </Col>
              <Row className="justify-content-center">
                <Col md="5">
                  <CardGroup>
                    <Card className="p-4 cardShadow">
                      <CardBody>
                        <Form onSubmit={this.login}>
                          <h3 className="mb-3">Login</h3>
                          {this.state.errorMessage !== "" ? (
                            <blockquote
                              style={{
                                margin: "24px 0",
                                padding: "4px 14px",
                                borderLeft: "5px solid rgb(0, 0, 0, 0.5)",
                                backgroundColor: "rgba(255, 100, 100, 0.2)",
                              }}
                            >
                              <p style={{ marginBottom: "0" }}>
                                <i class="icon-info icons d-inline-block align-middle mr-3 text-danger font-xl"></i>
                                {this.state.errorMessage}
                              </p>
                            </blockquote>
                          ) : (
                            ""
                          )}

                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>

                            <Input
                              type="text"
                              placeholder="Username"
                              onChange={this.usernameChange}
                              autoComplete="username"
                              className={
                                !this.state.isUserNameValid ? "is-invalid" : ""
                              }
                              value={this.state.username}
                            />
                            {!this.state.isUserNameValid ? (
                              <div class="invalid-feedback d-block">
                                Username is required.
                              </div>
                            ) : (
                              ""
                            )}
                          </InputGroup>
                          <InputGroup className="mb-1">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type={this.state.inputType}
                              placeholder="Password"
                              onChange={this.passwordChange}
                              autoComplete="current-password"
                              className={
                                !this.state.isPasswordValid ? "is-invalid" : ""
                              }
                              value={this.state.password}
                            />

                            <InputGroupAddon addonType="append">
                              <Button
                                color="secondary"
                                className="ml-2 btn-sm"
                                onClick={this.togglePassword}
                              >
                                <i
                                  className={
                                    this.state.inputType === "text"
                                      ? "fa fa-eye"
                                      : "fa fa-eye-slash"
                                  }
                                ></i>
                              </Button>
                            </InputGroupAddon>
                            {!this.state.isPasswordValid ? (
                              <div class="invalid-feedback d-block">
                                Password is required.
                              </div>
                            ) : (
                              ""
                            )}
                          </InputGroup>

                          <Row className="mt-2">
                            {/* <Col xs="6" className="">
                              <div className="stayLoggedin">
                                <FormGroup check style={{ paddingTop: 5 }}>
                                  <Label check style={{ fontSize: 13 }}>
                                    <Input
                                      type="checkbox"
                                      checked={this.state.isKeepMeSignedIn}
                                      onChange={this.handleKeepMeSignIn}
                                    />{" "}
                                    Keep me logged in */}
                            {/* <LightTooltip
                                      title={
                                        <>
                                          <div className="logginInfo">
                                            <h6>
                                              "Keep Me Signed In" Checkbox
                                            </h6>
                                            <span>
                                              Choosing "Keep me signed in"
                                              reduces the number of times you're
                                              asked to Sign-In on this device.
                                              To keep your account secure, use
                                              this option only on your personal
                                              devices.
                                            </span>
                                          </div>
                                        </>
                                      }
                                      placement="top"
                                    >
                                      <InfoOutlinedIcon />
                                    </LightTooltip> */}
                            {/* </Label>
                                </FormGroup>
                              </div>
                            </Col> */}
                            <Col xs="12" className="text-right">
                              <Button
                                color="link"
                                className="px-0"
                                onClick={this.redirectToForgotPassword}
                              >
                                Forgot password?
                              </Button>
                            </Col>
                            <Col xs="12" className="mt-1">
                              <Button
                                id="loginBtn"
                                color="primary"
                                className="px-3"
                                size="sm"
                                disabled={this.state.disableLoginBtn}
                                onkeydown={(event) => this.handleKeyDown(event)}
                              >
                                Login
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </CardGroup>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
